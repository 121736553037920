<template>
  <b-card
      no-body
      class="mt-1"
  >
    <app-collapse
      accordion
      margin
    >
      <app-collapse-item title="Search">
        <div class="demo-vertical-spacing">
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>Specialization</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="specializationFilter"
                :options="specializationOptions"
                :reduce="val => val.value"
                class="w-100"
                :clearable="true"
                @input="(val) => $emit('update:specializationFilter', val)"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>Search by name</label>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  :value="fullNameSearchQuery"
                  placeholder="Search..."
                  @input="(val) => $emit('update:fullNameSearchQuery', val)"
                />
              </b-input-group>
            </b-col>
          </b-row>

          <!-- Clear Filter Values -->
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="clearFilterValues"
                >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BFormInput, BRow, BCol, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  props: {
    specializationFilter: {
      type: Object,
      default: null,
    },
    specializationOptions: {
      type: Array,
      required: true,
    },
    fullNameSearchQuery: {
      type: String,
      default: '',
    },
  },
  methods: {
    clearFilterValues() {
      this.$emit('update:specializationFilter', null)
      this.$emit('update:fullNameSearchQuery', '')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
