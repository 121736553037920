<template>

  <div>

    <doctor-add
      :is-add-new-doctor-sidebar-active.sync="isAddNewDoctorSidebarActive"
      :specialization-options="specializationOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <doctor-list-filters
      :specialization-filter.sync="specializationFilter"
      :full-name-search-query.sync="fullNameSearchQuery"
      :specialization-options="specializationOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <!-- Table Top -->
      <div class="m-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mt-0.5 mb-1 mb-md-0"
          >
            <h2
              class="text-nowrap font-weight-bolder"
              hidden
            >
              Doctors:
            </h2>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddNewDoctorSidebarActive = true"
              >
                <span class="text-nowrap">Add Doctor</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table Content -->
      <b-table
        ref="refDoctorListTable"
        class="position-relative"
        :items="doctorsList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Doctor FullName -->
        <template #cell(Doctor)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.FullName)"
                :variant="`light-${resolveDoctorVariant()}`"
                :to="{ name: 'doctor-view', params: { id: data.item.Id } }"
              />
            </template>
            <b-link
              :to="{ name: 'doctor-view', params: { id: data.item.Id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.FullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.UserName }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`doctor-row-${data.item.Id}-doctor-view`"
              icon="EyeIcon"
              size="16"
              class="mx-0"
              @click="$router.push({ name: 'doctor-view', params: { id: data.item.Id }})"
            />
            <b-tooltip
              title="Preview Doctor"
              :target="`doctor-row-${data.item.Id}-doctor-view`"
            />

            <feather-icon
              :id="`doctor-row-${data.item.Id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'doctor-edit', params: { id: data.item.Id }})"
            />
            <b-tooltip
              title="Edit Doctor"
              :target="`doctor-row-${data.item.Id}-edit-icon`"
            />

            <feather-icon
              :id="`doctor-row-${data.item.Id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer"
              @click="deleteDoctor(data.item)"
            />
            <b-tooltip
              title="Delete Doctor"
              :target="`doctor-row-${data.item.Id}-delete-icon`"
            />
          </div>
        </template>
      </b-table>

      <!-- Table bottom: Pagination-->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing
              </span>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <span class="text-nowrap"> {{ totalDoctors }} records </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} records</span>
          </b-col>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDoctors"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BLink, BTooltip, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import { title, avatarText } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import doctorStoreModule from '@/store/main/DoctorStoreModule'
import DoctorListFilters from './DoctorListFilters.vue'
import DoctorAdd from '../doctor-add/DoctorAdd.vue'

export default {
  components: {
    DoctorListFilters,
    DoctorAdd,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BTooltip,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const DOCTOR_APP_STORE_MODULE_NAME = 'doctor-store'
    // Register module
    if (!store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.registerModule(DOCTOR_APP_STORE_MODULE_NAME, doctorStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCTOR_APP_STORE_MODULE_NAME)
    })

    const isAddNewDoctorSidebarActive = ref(false)
    // Use toast
    const toast = useToast()
    const refDoctorListTable = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'Doctor', sortable: true },
      {
        key: 'Mobile',
        label: 'Mobile',
        sortable: false,
      },
      {
        key: 'Specialization.Name',
        label: 'Specialization',
        formatter: title,
        sortable: true,
      },
      { key: 'actions' },
    ]
    const perPage = ref(10)
    const totalDoctors = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const fullNameSearchQuery = ref('')
    const sortBy = ref('CreationDate')
    const isSortDirDesc = ref(false)
    const specializationFilter = ref(null)

    const dataMeta = computed(() => {
      const localItemsCount = refDoctorListTable.value ? refDoctorListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalDoctors.value,
      }
    })

    const refetchData = () => {
      refDoctorListTable.value.refresh()
    }

    watch([currentPage, perPage, fullNameSearchQuery, specializationFilter], () => {
      refetchData()
    })

    const doctorsList = (ctx, callback) => {
      store
        .dispatch('doctor-store/getAllDoctors', {
          pageSize: perPage.value,
          pageNumber: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value === true ? 'DESC' : 'ASC',
          specialization: specializationFilter.value,
          fullName: fullNameSearchQuery.value,
        })
        .then(response => {
          if (response.data.IsSucceeded === true) {
            totalDoctors.value = response.data.Data.TotalCount
            const doctors = response.data.Data.List
            callback(doctors)
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching doctors list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const resolveDoctorVariant = () => {
      switch (Math.floor(Math.random() * (4 - 0)) + 0) {
        case 0: return 'primary'
        case 1: return 'warning'
        case 2: return 'success'
        case 3: return 'info'
        case 4: return 'danger'
        default: return 'primary'
      }
    }

    store
      .dispatch('doctor-store/getAllSpecializations')
      .then()
      .catch(() => {
        // console.log('Error fetching Specializations list for dropdown')
      })
    const specializationOptions = computed(() => store.state['doctor-store'].specializations)

    function deleteDoctor(doctor) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mr-1',
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this doctor ${doctor.FullName}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('doctor-store/deleteDoctor', { id: doctor.Id })
            .then(() => {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
              refetchData()
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Error happenied when deleting doctor',
                'error',
              )
            })
        } else if (result.dismiss === 'cancel') {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error',
          )
        }
      })
    }

    return {
      // Sidebar
      isAddNewDoctorSidebarActive,

      doctorsList,
      tableColumns,
      perPage,
      currentPage,
      totalDoctors,
      dataMeta,
      perPageOptions,
      fullNameSearchQuery,
      sortBy,
      isSortDirDesc,
      refDoctorListTable,
      refetchData,
      deleteDoctor,

      // Filter
      avatarText,
      resolveDoctorVariant,

      // Extra Filters
      specializationFilter,
      specializationOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
